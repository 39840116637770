body, html {
	font-family: Roboto, sans-serif;
}

h1, h2, h3, h4, h5, h6 {
	font-family: Roboto, sans-serif;
}

p, a, li, span, div {
	font-family: Roboto, sans-serif;
}

.floating-ivw-logo {
	display: none;
}

.nav .nav-logo--large img {
	height: 15.5rem;
}

// nav social media links
.nav-social-media-links {
	padding-left: rem(14px);

	@include media-breakpoint-up(xl) {
		display: flex !important;
	}

	a {
		margin-left: 1.125rem;
		position: relative;
		overflow: hidden;
		display: block;
		height: 48px;
		width: 48px;

		.mdi {
			justify-content: center;
			align-items: center;
			display: flex;
			color: white;
			line-height: 2.5rem;
			font-size: 2.5rem;
			height: 48px;
			width: 48px;
		}
	}
}